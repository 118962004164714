import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  InputAdornment,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  IconButton,
  Button,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import Navigator from '../components/navigator';
import Layout from '../components/layout';
import AuthenticatedPage from '../components/authenticated-page';

import {
  checkJWTExpired,
  getToken,
  navigate,
  getRoleId,
  parseGraphQLErrorMessage,
  isEmailValid,
  getVaspCode,
} from '../utils/utils';
import {
  CREATE_VASP,
  GET_REVIEW_LIST,
  GET_VASP,
  VASP_NET_SEARCH_VASP,
  VERIFY_VASP_EMAIL,
} from '../crud';
import { RoleId, ErrorMessages, VaspRegisterType, OperationStatus } from '../constants';
import {
  LoadingButton,
  ErrorDialog,
  CustomDialog,
  HookFormTextField,
  RegulationTable,
} from '../components/common';
import $ from '../config';
import CustomVaspRecipients from '../components/common/recipients/customVaspRecipients';
import OtherEmailRecipients from '../components/common/recipients/otherEmailRecipients';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tableHeadRow: {
    backgroundColor: '#DFDFDF',
  },
  tableContainer: {
    marginTop: '1em',
    marginBottom: '1em',
  },
}));

export default function CreateVasp() {
  const token = getToken();
  if (!token || checkJWTExpired(token)) {
    // push back to home if not logged in or token expired
    navigate('/');
  }

  const vaspRoleId = getRoleId(token);
  if (vaspRoleId !== RoleId.ACCOUNT_MANAGER && vaspRoleId !== RoleId.ADMIN) {
    navigate('/vasp-list');
  }
  const [vaspDetail, setVaspDetail] = useState({
    vasp_email: null,
    vasp_name: null,
    token_expires_in: 14,
    registration_type: VaspRegisterType.Bridge,
  });

  const classes = useStyles();
  const [createVasp] = useMutation(CREATE_VASP);
  const [verifyVaspEmail] = useMutation(VERIFY_VASP_EMAIL);

  const [informDialogOpen, setInformDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [requestError, setRequestError] = useState('');

  // search vasp in vasp net
  const [vaspSearchData, setVaspSearchData] = useState([]);
  const [vaspSearchSelectedIndex, setVaspSearchSelectedIndex] = useState(-1);
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [doubleConfirmDialogOpen, setDoubleConfirmDialogOpen] = useState(false);

  const [createLoading, setCreateLoading] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  const [recipientOptions, setRecipientOptions] = useState({
    all: true,
    customVaspRecipients: false,
    otherEmails: false,
  });

  const handleRecipientOptionsChange = (event) => {
    setRecipientOptions({
      ...recipientOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const timer = React.useRef();

  useEffect(() => clearTimeout(timer.current), []);
  const needDoubleConfirm = $.GATSBY_ENV !== 'prod';
  const formProps = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      ...vaspDetail,
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const { handleSubmit, register, errors, setValue, getValues, control } = formProps;

  const [searchVasp, { loading: searchLoading }] = useLazyQuery(VASP_NET_SEARCH_VASP, {
    variables: {
      vaspName: getValues().vasp_name,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (!data || !data.searchVaspRegulation) {
        setCreateLoading(false);
        setErrorDialogOpen(true);
        setRequestError(parseGraphQLErrorMessage('data is undefined'));
        return;
      }
      setCreateLoading(false);
      setVaspSearchData(data.searchVaspRegulation);
    },
    onError: (error) => {
      setCreateLoading(false);
      setErrorDialogOpen(true);
      setRequestError(parseGraphQLErrorMessage(error));
    },
  });

  const onSearchButtonClick = (e) => {
    if (_.get(getValues(), ['vasp_name', 'length'], 0) === 0) {
      e.preventDefault();
      return;
    }
    if (needDoubleConfirm) {
      setDoubleConfirmDialogOpen(true);
      return;
    }
    searchVasp();
    setSearchDialogOpen(true);
  };

  const onSubmit = async (data) => {
    setCreateLoading(true);
    try {
      const vaspNetIdentifier =
        vaspSearchSelectedIndex >= 0
          ? vaspSearchData[vaspSearchSelectedIndex].vaspIdentifier
          : undefined;
      const countryCode =
        vaspSearchSelectedIndex >= 0
          ? vaspSearchData[vaspSearchSelectedIndex].countryCode
          : undefined;

      const otherEmails = data?.recipients?.otherEmails?.map((v) => v.value) || [];
      await createVasp({
        variables: {
          input: {
            vasp_name: data.vasp_name,
            vasp_email: data.vasp_email,
            registration_type: data.registration_type,
            token_expires_in: `${data.token_expires_in} days`,
            vasp_net_identifier: vaspNetIdentifier,
            country_code: countryCode,
            recipient_options: recipientOptions,
            recipients: {
              customVasps: data?.recipients?.customVasps?.map((v) => v.value) || [],
              otherEmails,
            },
          },
        },
        context: {
          headers: {
            authorization: token,
          },
        },
      });
      setVaspDetail(data);
      setCreateLoading(false);
      setCreateSuccess(true);
      setInformDialogOpen(true);
      if (otherEmails.length > 0) {
        const usedEmailHistory = JSON.parse(localStorage.getItem('usedEmailHistory') ?? '[]') || [];
        usedEmailHistory.push(...otherEmails);
        localStorage.setItem('usedEmailHistory', JSON.stringify(_.uniq(usedEmailHistory)));
      }
    } catch (error) {
      setCreateLoading(false);
      setErrorDialogOpen(true);
      setRequestError(parseGraphQLErrorMessage(error));
    }
  };

  const handleCreateSuccess = () => {
    setInformDialogOpen(false);
    navigate('/vasp-list');
  };

  const isTokenExpirationPeriodValid = (tokenExpireDays) => {
    if (!tokenExpireDays) {
      return true;
    }
    return Number(tokenExpireDays) >= 1 && Number(tokenExpireDays) <= 14;
  };

  const isEmailExisted = async (email) => {
    if (!email) {
      return false;
    }
    const result = await verifyVaspEmail({
      variables: {
        email,
      },
      context: {
        headers: {
          authorization: token,
        },
      },
    });
    return result.data.verifyVaspEmail;
  };

  return (
    <Layout>
      <AuthenticatedPage>
        <Navigator
          breadcrumbTexts={['Home', 'Vasp List', 'Create Vasp']}
          breadcrumbLinks={['/', '/vasp-list', '/create-vasp']}
        />
        <br />
        <div className={classes.container}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <HookFormTextField
              id="vasp_email"
              name="vasp_email"
              style={{ margin: 8, width: 400 }}
              fullWidth={false}
              label="Registered Email"
              placeholder="Input email"
              forwardedRef={register({
                required: true,
                validate: {
                  isEmailValid,
                  isEmailExisted,
                },
              })}
              onChange={(e) => setValue('vasp_email', e.target.value)}
              error={errors.vasp_email}
              parseError={(e) => {
                const type = _.get(e, ['type'], undefined);
                if (!type) {
                  return '';
                }
                if (type === 'required') {
                  return ErrorMessages.Required;
                }
                if (type === 'isEmailExisted') {
                  return ErrorMessages.ExistedEmail;
                }
                return ErrorMessages.InvalidEmailFormat;
              }}
            />

            <InputLabel
              htmlFor="registration_type"
              style={{ margin: 8, width: 400, fontSize: '16px' }}
            >
              Registration Type
            </InputLabel>
            <Controller
              control={control}
              name="registration_type"
              as={
                <Select id="registration_type" style={{ margin: 8, width: 400 }}>
                  {Object.entries(VaspRegisterType).map(([k, v]) => (
                    <MenuItem value={v}>{k}</MenuItem>
                  ))}
                </Select>
              }
            />

            <HookFormTextField
              id="vasp_name"
              name="vasp_name"
              style={{ margin: 8, width: 400 }}
              fullWidth={false}
              label="Registered Company Name (Company Legal name)"
              placeholder="Input company name"
              forwardedRef={register({
                required: true,
              })}
              onChange={(e) => {
                setValue('vasp_name', e.target.value);
              }}
              error={errors.vasp_name}
              parseError={(e) => {
                const type = _.get(e, ['type'], undefined);
                if (!type) {
                  return '';
                }
                return ErrorMessages.Required;
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="search">
                      <IconButton onClick={(e) => onSearchButtonClick(e)} id="vasp_net_search_btn">
                        <SearchIcon color="inherit" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            {$.GATSBY_ENV !== 'prod' && (
              <span style={{ color: 'red', paddingLeft: 10 }}>
                Please be noted that &quot;TEST&quot; backoffice is optional to hit VASPnet search
                in order to save credits. It will be mandatory and recommended to hit VASPnet search
                in &quot;Production&quot; backoffice
              </span>
            )}

            <HookFormTextField
              id="token_expires_in"
              name="token_expires_in"
              style={{ margin: 8, width: 400 }}
              fullWidth={false}
              label="Registration Link Expiration"
              placeholder="Input token expiration period"
              InputProps={{
                endAdornment: <InputAdornment position="end">days</InputAdornment>,
                inputProps: {
                  max: 14,
                  min: 1,
                  step: 1,
                },
              }}
              type="number"
              forwardedRef={register({
                required: true,
                validate: isTokenExpirationPeriodValid,
              })}
              onChange={(e) => setValue('token_expires_in', e.target.value)}
              error={errors.token_expires_in}
              parseError={(e) => {
                const type = _.get(e, ['type'], undefined);
                if (!type) {
                  return '';
                }
                if (type === 'required') {
                  return ErrorMessages.Required;
                }
                return ErrorMessages.InvalidTokenExpirationPeriod;
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div style={{ padding: 8 }}>
                  <FormLabel component="legend">
                    Notification recipients when registration is successful
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ margin: 0 }}
                          checked={recipientOptions.all}
                          disabled={recipientOptions.customVaspRecipients}
                          onChange={handleRecipientOptionsChange}
                          name="all"
                          color="primary"
                        />
                      }
                      label="All Sygna VASP"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recipientOptions.customVaspRecipients}
                          disabled={recipientOptions.all}
                          onChange={handleRecipientOptionsChange}
                          name="customVaspRecipients"
                          color="primary"
                        />
                      }
                      label="Custom VASP Recipients"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recipientOptions.otherEmails}
                          onChange={handleRecipientOptionsChange}
                          name="otherEmails"
                          color="primary"
                        />
                      }
                      label="Other Emails"
                    />
                  </FormGroup>
                </div>
              </Grid>
              <Grid item xs={6}>
                {recipientOptions.customVaspRecipients ? (
                  <CustomVaspRecipients {...formProps} />
                ) : null}
                {recipientOptions.otherEmails ? <OtherEmailRecipients {...formProps} /> : null}
              </Grid>
            </Grid>
            <Grid item>
              <div style={{ display: 'inline-flex', justifyContent: 'flex-start' }}>
                <LoadingButton
                  id="create"
                  buttonStyle={{ marginTop: 20 }}
                  loading={createLoading}
                  done={{ get: createSuccess, set: setCreateSuccess }}
                  buttonType="submit"
                  buttonText="Create"
                />
              </div>
            </Grid>
          </form>
        </div>
      </AuthenticatedPage>
      <ErrorDialog
        id="error_dialog"
        open={{ get: errorDialogOpen, set: setErrorDialogOpen }}
        message={requestError}
      />
      <CustomDialog
        id="confirm_dialog"
        open={{ get: informDialogOpen, set: setInformDialogOpen }}
        title="Inform"
        message={`${vaspDetail.vasp_name} Create Success`}
        positiveButtonText="ok"
        onClose={handleCreateSuccess}
        onPositiveButtonClick={handleCreateSuccess}
        timeout={5000}
      />
      <CustomDialog
        id="confirm_vaspnet_dialog"
        open={{ get: doubleConfirmDialogOpen, set: setDoubleConfirmDialogOpen }}
        title="Inform"
        message="Are you sure you want to search this VASP using VASPnet?"
        positiveButtonText="ok"
        negativeButtonText="cancel"
        onClose={() => {
          setDoubleConfirmDialogOpen(false);
        }}
        onNegativeButtonClick={() => {
          setDoubleConfirmDialogOpen(false);
        }}
        onPositiveButtonClick={() => {
          searchVasp();
          setDoubleConfirmDialogOpen(false);
          setSearchDialogOpen(true);
        }}
        timeout={10 * 1000}
      />
      <Dialog
        maxWidth="lg"
        open={searchDialogOpen}
        onClose={() => setSearchDialogOpen(false)}
        id="search_dialog"
      >
        <DialogTitle>VASP Net Regulation Search Result</DialogTitle>
        <DialogContent>
          <RegulationTable
            loading={searchLoading}
            data={vaspSearchData}
            selectedIndex={vaspSearchSelectedIndex}
            setSelectedIndex={setVaspSearchSelectedIndex}
            selectable
          />
          {/* <div style={{ textAlign: 'center' }}>
            {!searchLoading && !_.get(vaspSearchData, [0], undefined) && (
              <p id="vasp_net_result_not_found">Not Found</p>
            )}
          </div> */}
          <Button
            id="vasp_net_result_confirm"
            variant="outlined"
            style={{ float: 'right' }}
            onClick={() => setSearchDialogOpen(false)}
          >
            Confirm
          </Button>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}
